@import "~normalize.css";

@import "~assets/styles/index.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

a:focus, button:focus {
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto";
  font-size: 14px;

  color: $color-foreground;
  background-color: $color-background;
  min-width: 1260px;
  min-height: 525px;
}

#root {
  display: flex;

  width: 100%;
  height: 100%;

  overflow: hidden;
}
