.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  background:#063249;

  height: 100%;
  width: 100%;
}

.body {
  display: flex;
  flex: 1;

  height: 100%;
  width: 100%;
  position: relative;
}
