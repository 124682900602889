$color-background: #063249;
$color-foreground: #072248;

$green: #DEE964;
$green-primary: #CEE550;
$green-secondary: #C2CD5E;
$snow: #FBFBFB;
$gray: #E3E3E3;
$white: #FFFFFF;
$dark-blue: #063249;
$light-blue: #2783D5;
$blue: #1673FB;
$blue-secondary: #265376;
$link: #4997DC;
$error: #E85A3F;
